#mainContainer {width: 100%; min-height: 90vh;  display: flex; padding: 20px 3%;}
#subConMenu {width: 18%; min-height: 300px; max-height: auto; display: flex; flex-direction: column;  padding: 10px 0 10px 0%; 
border-right: 1px solid #DDDEE4; margin: 10px 2%; }
#subConContent {width: 75%; height: auto; display: flex; flex-direction: column;  }
.sideMenuItemSubCon, .subSubMenu > p, .MyPs {
    color: inherit;
    display: flex;
    width: 100%;
    height: 30px;
    padding: 2px 2%; /* Add padding around the text */    
    cursor: pointer; /* Change cursor to pointer when hovering */
    text-align: left; /* Align text to the left */ 
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    font-size: 1em;
}
.subSubMenu {
    color: inherit;
    margin-left: auto;    
    display: flex;  
    min-height: 40px;
    flex-direction: column;
    width: 90%;
    padding: 2px 2%;
    border-left: 1px solid #cac5c5;
}
.subMenuItems {margin-left: 3%;}
/* Hover effect for sideMenuItem */
.sideMenuItemSubCon:hover, .MyPs:hover, .subSubMenu > p:hover {
    background-color: #f1f1f1; /* Change background color on hover */
    color: #29B3CB; /* Change text color on hover */
    font-weight: bold; /* Make text bold on hover */
}
@media (max-width: 600px) {
    #mainContainer {width: 100%; min-height: 90vh;  display: flex; padding: 20px 0.5%; flex-direction: column;}
#subConMenu {width: 21%; min-height: 300px; max-height: auto; display: flex; flex-direction: column;  padding: 10px 0; 
border-right: 1px solid #DDDEE4; margin: 10px 2%; display: none;}
#subConContent {width: 100%; height: auto; display: flex; flex-direction: column;  }
.sideMenuItemSubCon, .subSubMenu > p, .MyPs {
    color: inherit;
    display: flex;
    width: 100%;
    height: 30px;
    padding: 2px 4%; /* Add padding around the text */    
    cursor: pointer; /* Change cursor to pointer when hovering */
    text-align: left; /* Align text to the left */ 
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    font-size:medium;
}
.subSubMenu {
    color: inherit;
    margin-left: auto;    
    display: flex;  
    min-height: 40px;
    flex-direction: column;
    width: 90%;
    padding: 2px 2%;
    border-left: 1px solid #cac5c5;
}
.subMenuItems {margin-left: 3%;}
/* Hover effect for sideMenuItem */
.sideMenuItemSubCon:hover, .subSubMenu > p:hover {
    background-color: #f1f1f1; /* Change background color on hover */
    color: #29B3CB; /* Change text color on hover */
    font-weight: bold; /* Make text bold on hover */
}
}