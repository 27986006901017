@media (min-width:601px) {
    #searchResultsDiv {background-color: #fff; position: absolute; top:55px; width: 40%; height: 300px; overflow-y: scroll; display: flex; flex-direction: column;
        padding: 10px 0;}
        #searchDiv {width: 100%;}
        .resultItems {width: 100%; margin-bottom: 2px; text-align: left; padding: 3px 2%; color: #555353;}
        .resultItems:hover {background-color:rgb(223, 217, 217);}
        
        #searchResultsDiv::-webkit-scrollbar {
            width: 8px;
        }
        
        #searchResultsDiv::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 4px;
        }
        
        #searchResultsDiv::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
}
@media (max-width:600px) {
    #searchResultsDiv {background-color: #fff; position: absolute; top:70px; width: 90%; height: 300px; overflow-y: scroll; display: flex; flex-direction: column;
        padding: 10px 0; left:5%;}
        #searchDiv {width: 100%;}
        .resultItems {width: 100%; margin-bottom: 2px; text-align: left; padding: 3px 2%; color: #555353;}
        .resultItems:hover {background-color:rgb(223, 217, 217);}
        
        #searchResultsDiv::-webkit-scrollbar {
            width: 8px;
        }
        
        #searchResultsDiv::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 4px;
        }
        
        #searchResultsDiv::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
}