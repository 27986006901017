@media (min-width:601px) {
    #mainRenderWrap {display: flex;flex-direction: column;}    
    #contentFilters { width:100%; height: 60px;}
    #contenteWrapper { width: 100%; min-height: 50vh; display: flex; flex-direction: column; margin-bottom: 10px;}    
    .productCard {
        color:#343a40;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        padding: 8px 2%;       
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 400px;
    }    
    #onLoading {width: 100%; background-color: #4CAF50; height: 100vh;}
    .productCard:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    }
    .allProdCont {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 85%;
    }
    .imageContainer {
        width: 60%;
        margin: 0 auto;
        height: 60%;
        overflow: hidden;
        border-radius: 8px;
    }
    
    .productImage {
        width: 100%;
        height: 100%;
        object-fit:contain;
        color:black;
    }
    
    .productDetails {
        height: 40%;
        width: 100%;
        color:black;
        padding: 4px;
    }
    
    .productName {
        height: 60%;
        width: 100%;
        font-weight: bold;
        font-size: 0.85em;
        margin: 4px 0;
        overflow:hidden;
    }
    
    .productPrice {
        color: #4CAF50;
        font-size: 1.5em;
        margin-top: 4px;
    }
    
    .addToCartButton {
        height: 12%;
        width: 100%;
        background-color: #4CAF50; 
        color: #fff; 
        padding: 8px 16px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        margin-top: 8px;
        font-size: 14px;
        font-weight: bold;
        transition: background-color 0.3s ease;
    }
    
    .addToCartButton:hover {
        background-color: #45a049; 
    }
    .paginationControls {
        display: flex; /* Use flexbox for layout */
        justify-content: center; /* Center the items horizontally */
        align-items: center; /* Center the items vertically */
        padding: 10px; /* Add padding around the controls */
        background-color: #f8f9fa; /* Light background color */
        border-top: 1px solid #dee2e6; /* Add a top border */
    }
    
    .paginationControls .forDefault {
        margin: 0 10px; /* Add horizontal margin between buttons */
        padding: 5px 10px; /* Padding inside buttons */
        background-color: #007bff;
        color: white; /* White text color */
        border: none; /* No border */
        border-radius: 4px; /* Rounded corners */
        cursor: pointer; /* Pointer cursor on hover */
        transition: background-color 0.2s; /* Smooth color transition */
    }
    
    .paginationControls button:hover {
        background-color: #0056b3; /* Darker blue on hover */
    }
    
    .paginationControls p {
        margin: 0; /* Remove default margin */
        font-size: 14px; /* Smaller font size */
        color: #343a40; /* Dark text color */
    }
    #forContentRender {
        width: 100%;
        padding: 10px 1%;
        display: grid;
        gap:10px 1%;
        grid-template: repeat(5, minmax(100px, auto)) /repeat(5, 18.5%);           
    }
    .forGreen {
        margin: 0 10px; /* Add horizontal margin between buttons */
        padding: 5px 10px; /* Padding inside buttons */
        background-color: #007bff;
        color: white; /* White text color */
        border: none; /* No border */
        border-radius: 4px; /* Rounded corners */
        cursor: pointer; /* Pointer cursor on hover */
        transition: background-color 0.2s; /* Smooth color transition */
        background-color: #4CAF50
    }
    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 60px;
        position: absolute;
        top:42vh;
        left:48%;
        height: 60px;
        animation: spin 2s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      .ASCButton {
        background-color: #8bafd5; /* Blue background */
        color: white; /* White text */
        border: none; 
        font-size: 1em; /* Font size */
        cursor: pointer; /* Pointer cursor on hover */
        border-radius: 5px; /* Rounded corners */
        padding: 4px 2%;
        transition: background-color 0.3s; /* Smooth background color transition */
    }
    
    .ASCButton:hover {
        background-color: #0056b3; /* Darker blue on hover */
    }
    
    .ASCButton:active {
        background-color: #004080; /* Even darker blue when active */
    }
    
    .ASCButton:disabled {
        background-color: #cccccc; /* Grey background when disabled */
        cursor: not-allowed; /* Not-allowed cursor when disabled */
    }
}

@media (max-width: 600px) {
    .ASCButton {
        background-color: #8bafd5; /* Blue background */
        color: white; /* White text */
        border: none; 
        font-size: 1em; /* Font size */
        cursor: pointer; /* Pointer cursor on hover */
        border-radius: 5px; /* Rounded corners */
        padding: 4px 2%;
        transition: background-color 0.3s; /* Smooth background color transition */
    }
    
    .ASCButton:hover {
        background-color: #0056b3; /* Darker blue on hover */
    }
    
    .ASCButton:active {
        background-color: #004080; /* Even darker blue when active */
    }
    
    .ASCButton:disabled {
        background-color: #cccccc; /* Grey background when disabled */
        cursor: not-allowed; /* Not-allowed cursor when disabled */
    }
    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 60px;
        position: absolute;
        top:42vh;
        left:48%;
        height: 60px;
        animation: spin 2s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    #contenteWrapper { width: 100%; min-height: 50vh; display: flex; flex-direction: column;}
    /* ProductList.module.css */
    #forContentRender {
        width: 100%;
        display: grid;
        gap:10px 4%;
        grid-template: repeat(13, minmax(100px, auto)) /repeat(2, 48%);    
        padding: 10px 0.5%;  
        
    }
    .paginationControls {
        display: flex; /* Use flexbox for layout */
        max-width: 600px;
        justify-content: center; /* Center the items horizontally */
        align-items: center; /* Center the items vertically */
        padding: 10px; /* Add padding around the controls */
        background-color: #f8f9fa; /* Light background color */
        border-top: 1px solid #dee2e6; /* Add a top border */
        overflow-x: auto;
    }
    .paginationControls .forDefault {
        margin: 0 2px; /* Add horizontal margin between buttons */
        padding: 2px 5px; /* Padding inside buttons */
        background-color: #007bff;
        color: white; /* White text color */
        border: none; /* No border */
        border-radius: 30%; /* Rounded corners */
        cursor: pointer; /* Pointer cursor on hover */
        transition: background-color 0.2s; /* Smooth color transition */
    }
    .forGreen {
        margin: 0 5px; /* Add horizontal margin between buttons */
        padding: 5px 8px; /* Padding inside buttons */
        background-color: #007bff;
        color: white; /* White text color */
        border: none; /* No border */
        border-radius: 4px; /* Rounded corners */
        cursor: pointer; /* Pointer cursor on hover */
        transition: background-color 0.2s; /* Smooth color transition */
        background-color: #4CAF50
    };
    
    .paginationControls button:hover {
        background-color: #0056b3; /* Darker blue on hover */
    }
    
    .paginationControls p {
        margin: 0; /* Remove default margin */
        font-size: 10px; /* Smaller font size */
        color: #343a40; /* Dark text color */
    }
    .productCard {
        color:#343a40;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        padding: 8px 2%;       
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 400px;
    }
    
    .productCard:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    }
    .allProdCont {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 85%;
    }
    .imageContainer {
        width: 60%;
        margin: 0 auto;
        height: 60%;
        overflow: hidden;
        border-radius: 8px;
    }
    
    .productImage {
        width: 100%;
        height: 100%;
        object-fit:contain;
        color:black;
    }
    
    .productDetails {
        height: 40%;
        width: 100%;
        color:black;
        padding: 4px;
    }
    
    .productName {
        height: 60%;
        width: 100%;
        font-weight: bold;
        font-size: 1em;
        margin: 4px 0;
        overflow: hidden;
    }
    
    .productPrice {
        color: #4CAF50;
        font-size: 14px;
        margin-top: 4px;
    }
    
    .addToCartButton {
        height: 12%;
        width: 100%;
        background-color: #4CAF50; 
        color: #fff; 
        padding: 8px 16px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        margin-top: 8px;
        font-size: 14px;
        font-weight: bold;
        transition: background-color 0.3s ease;
    }
}