@media (min-width: 601px) {
    .controls {
      display: flex;  
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
    
    .prevButton,
    .nextButton {
      background-color: #555;
      color: white;
      padding: 10px;
      border: none;
      cursor: pointer;
    }
    
    .indicators {
      display: flex;
      justify-content: center;  
      padding: 10px 0;
      align-items: center;
    }
    
    .indicator {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background-color: gray;
      border-radius: 50%;
      cursor: pointer;
    }
    
    .active {
      background-color: black;
    }
    .slide {
    justify-content: center;
      align-items: center;
      display: flex;
      height: 70vh; /* Adjust the height as needed */
      overflow: hidden; /* Ensures images don't overflow the container */
    }
    .slideshow {
      position: relative;
      width: 50%;
      height: auto; 
      margin-left:25%;
    }
    
    .slide img {  
      height: auto; /* Ensures the height matches the container */
      border-radius: 5px;
      max-width: 80%;
    }
    
    .leftButton,
    .rightButton {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      font-size: 24px;
      padding: 10px 1%;
      border-radius: 50%;
      cursor: pointer;
      
    }
    .leftButton:hover,
    .rightButton:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
    .leftButton {
      left: 1%;
    }
    
    .rightButton {
      right: 1%;
    }
    
    .controls {
      text-align: center;
      margin-top: 1%;
    }
    
    .indicators {
      display: inline-block;
    }
    
    .indicator {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background-color: #bbb;
      border-radius: 50%;
      cursor: pointer;
    }
    
    .indicator.active {
      background-color: #717171;
    }
  }
  @media (max-width: 600px) {
    .controls {
      display: flex;  
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
    
    .prevButton,
    .nextButton {
      background-color: #555;
      color: white;
      padding: 10px;
      border: none;
      cursor: pointer;
    }
    
    .indicators {
      display: flex;
      justify-content: center;  
      padding: 10px 0;
      align-items: center;
    }
    
    .indicator {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background-color: gray;
      border-radius: 50%;
      cursor: pointer;
    }
    
    .active {
      background-color: black;
    }
    .slide {
    justify-content: center;
      align-items: center;
      display: flex;
      height: 70vh; /* Adjust the height as needed */
      overflow: hidden; /* Ensures images don't overflow the container */
    }
    .slideshow {
      position: relative;
      width: 100%;  
      margin: auto;
    }
    
    .slide img {  
      height: auto; /* Ensures the height matches the container */
      object-fit:contain; /* Keeps the aspect ratio and covers the container */
      border-radius: 5px;
      max-width: 100%;
    }
    
    .leftButton,
    .rightButton {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      font-size: 24px;
      padding: 10px 1%;
      border-radius: 50%;
      cursor: pointer;
      
    }
    .leftButton:hover,
    .rightButton:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
    .leftButton {
      left: 1%;
    }
    
    .rightButton {
      right: 1%;
    }
    
    .controls {
      text-align: center;
      margin-top: 1%;
    }
    
    .indicators {
      display: inline-block;
    }
    
    .indicator {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background-color: #bbb;
      border-radius: 50%;
      cursor: pointer;
    }
    
    .indicator.active {
      background-color: #717171;
    }
  }