
@media (min-width:601px){
#entPurchaseContent {width: 100%; height: 100vh; background-color: rgba(26, 22, 22, 0.3); display: flex; position: absolute; top:0;}
#innPurchaseContent {width: 60%; height: 70vh; display: grid; grid-template: 50px auto 100px/100%; padding: 20px 1.5%;
 background-color: #ffffff; margin: 15vh 20%; border-radius: 6px; overflow-y: scroll;}
 #innPurchaseContent > div {border-bottom: 2px solid #EDEDED; padding: 5px 2%;}
#innPurConHead {width: 100%; height: 50px;  display: flex;  
    color: #333; align-items: center; font-size: 30px;}
#innPurConBody {width: 100%; height: auto;   display: flex; flex-direction: column; color: #333; }
.cartItems { width: 100%; display: flex; flex-direction: column; height: 215px; padding: 10px 2%; border-bottom: 1px solid #E9E9E9; margin-bottom: 5px;}
.topCartItem {width: 100%; height:140px; display: flex;}
.cartImage {width: 20%; height: 140px; background-color: #fff; justify-content: center;}
.cartName {width: 50%; text-align: left; font-size: 1.2em; height: 140px; background-color: #fff;}
.cartImage > img {height: 100%;}
.cart3signItem {font-size: 1.7em; margin-left: auto;}
.cart3signItemInner {cursor: pointer;}
.bottomCartItem {width: 100%; height:50px; margin-bottom: 5px;}
.priceCartItem {width: 50%; height: 50px; margin-left: auto; display: flex;}
.cartAmount {display: flex; width: 30%; height: 50px; justify-content: center; line-height: 50px; background-color: #E9E9E9;}
.plusButton, .minusButton {width: 30%; height: 50px; }
.cartInput {font-size: 2em; width: 40%; text-align: center;}

.cartItemPrice {display: flex; width: 50%; height: 50px; justify-content: end; line-height: 50px; font-size: 1.5em;}
#innPurConApply {width: 100%; height: 100px;   display: flex; color: #333;}
#cartBuy {display: flex; justify-content: space-between; width: 60%; border-radius: 5%; margin-left: auto; height: 70px; background-color: #E5F5EC; padding: 10px 1%; }
#resultSum {width: 50%; height: 50px; color: #333; font-size: 2em; font-weight: bold; text-align: center; line-height: 50px;}
#proceedCheckOut {width: 38%; height: 50px; font-size: 1.3em; text-align: center; line-height: 50px; background-color: #00BC52; border-radius: 5px; color: #fff;}
#nameBin {font-weight: bold;}
#closeBin {margin-left: auto; cursor: pointer;}
#closeBin:hover {color: black; font-size: 1.2em;}}
@media (max-width:600px){
    #entPurchaseContent {width: 100%; height: 100vh; background-color: rgba(26, 22, 22, 0.3); display: flex; position: absolute; top:70px;}
    #innPurchaseContent {width: 100%; min-height: 80vh; display: grid; grid-template: 50px auto 100px/100%; padding: 20px 0;
     background-color: #ffffff; margin: 0 0; border-radius: 6px; overflow-y: scroll;}
     #innPurchaseContent > div {border-bottom: 2px solid #EDEDED; padding: 5px 2%;}
    #innPurConHead {width: 100%; height: 50px;  display: flex;  
        color: #333; align-items: center; font-size: 30px;}
    #innPurConBody {width: 100%; height: auto;   display: flex; flex-direction: column; color: #333; }
    .cartItems { width: 100%; display: flex; flex-direction: column; height: 215px; padding: 10px 2%; border-bottom: 1px solid #E9E9E9; margin-bottom: 5px;}
    .topCartItem {width: 100%; height:140px; display: flex;}
    .cartImage {width: 20%; height: 140px; background-color: #fff; justify-content: center;}
    .cartName {width: 50%; text-align: left; font-size: 1.2em; height: 140px; background-color: #fff;}
    .cartImage > img {height: 100%;}
    .cart3signItem {font-size: 1.7em; margin-left: auto;}
    .cart3signItemInner {cursor: pointer;}
    .bottomCartItem {width: 100%; height:50px; margin-bottom: 5px;}
    .priceCartItem {width: 100%; height: 50px; margin-left: auto; display: flex;}
    .cartAmount {display: flex; width: 30%; height: 50px; justify-content: center; line-height: 50px; background-color: #E9E9E9;}
    .plusButton, .minusButton {width: 30%; height: 50px; }
    .cartInput {font-size: 2em; width: 40%; text-align: center;}
    
    .cartItemPrice {display: flex; width: 50%; height: 50px; justify-content: end; line-height: 50px; font-size: 1.5em;}
    #innPurConApply {width: 100%; height: 100px;   display: flex; color: #333;}
    #cartBuy {display: flex; justify-content: space-between; width: 100%; border-radius: 5%; margin-left: auto; height: 70px; background-color: #E5F5EC; padding: 10px 1%; }
    #resultSum {width: 30%; height: 50px; color: #333; font-size: 2em; font-weight: bold; text-align: center; line-height: 50px;}
    #proceedCheckOut {width: 60%; height: 50px; font-size: 1.3em; text-align: center; line-height: 50px; background-color: #00BC52; border-radius: 5px; color: #fff;}
    #nameBin {font-weight: bold;}
    #closeBin {margin-left: auto; cursor: pointer;}
    #closeBin:hover {color: black; font-size: 1.2em;}}