.contactsPage {
    
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    padding: 0 2%;
  }
  
  .contactInfo {
   
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .map {
    display: flex;
    flex-direction: column; /* Update to column to stack map and form vertically */
  }
  
  .map > div {
    margin-bottom: 20px; /* Add margin between map and form */
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form label {
    margin-bottom: 10px;
  }
  
  form input,
  form textarea {
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 600px;
  }
  
  form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #0056b3;
  }
  




  .newsItem {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .newsItem:hover {
    transform: scale(1.02);
  }
  
  .newsDate {
    font-size: 14px;
    color: #888;
    margin-bottom: 8px;
  }
  
  .newsContent {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
  }
  