#wholePage {position: fixed;
    z-index: 3;
    top: 70px; /* 20px from the top of the container */
    left:0;
    width: 100%;
    min-width: 1600px;
    height: 100vh; justify-content: center; background-color: rgba(26, 22, 22, 0.3);  
    display: flex;}
#forHeadDropDown {
    z-index: 1;
    margin-top: 0px;
    width: 70%;
    height: 50vh;    
    display: flex;
    font-size:1em;
}
.drDownIcons {margin-left: 3%;}
.drDownItems {margin-left: 3%;}
#sideMenuHeadDrDo {display: flex; min-height: 300px; flex-direction: column; width: 28%; 
    border-right: 1px solid #cac5c5;   background-color: #ffffff; padding: 4px 0; color: #221F1F; border-bottom-left-radius: 5px; overflow-y: auto;
    ::-webkit-scrollbar {
        display: none;
    }}
#contentHeadDrDo {display: flex; min-height: 300px; flex-direction: column; width: 70%; background-color: #ffffff;
    padding: 4px 1%; color: #221F1F; border-bottom-right-radius: 5px; }
.sideMenuItem {
    display: flex;
    width: 100%;
    height: 30px;
    padding: 2px 4%; /* Add padding around the text */    
    cursor: pointer; /* Change cursor to pointer when hovering */
    text-align: left; /* Align text to the left */ 
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    color: inherit;
}

/* Hover effect for sideMenuItem */
.sideMenuItem:hover {
    background-color: #f1f1f1; /* Change background color on hover */
    color: #29B3CB; /* Change text color on hover */
    font-weight: bold; /* Make text bold on hover */
}
.saleItem {
    color: red;
    font-weight: bold;
    background-color: yellow;
    
    border: 2px solid red;
    border-radius: 5px;
    text-transform: uppercase;
    /* Add more styles as needed */
  }
@media (max-width: 600px) {
    .saleItem {
        color: red;
        font-weight: bold;
        background-color: yellow;
        
        border: 2px solid red;
        border-radius: 5px;
        text-transform: uppercase;
        /* Add more styles as needed */
      }
    #wholePage {position: fixed;
        top: 70px; /* 20px from the top of the container */
        left:0;
        width: 100%;
        min-width: auto;
        height: 100vh; justify-content: center; background-color: rgba(26, 22, 22, 0.3);  
        display: flex;}
    #forHeadDropDown {
        margin-top: 0px;
        width: 100%;
        height: auto;    
        display: flex;
        font-size: small;
    }
    .drDownIcons {margin-left: 3%;}
    .drDownItems {margin-left: 3%;}
    #sideMenuHeadDrDo {display: flex; min-height: 200px; flex-direction: column; width: 80%; 
        border-right: 1px solid #cac5c5;   background-color: #ffffff; padding: 4px 0; color: #221F1F; border-bottom-left-radius: 5px; }
    #contentHeadDrDo {display: none; min-height: 300px; flex-direction: column; width: 75%; background-color: #ffffff;padding: 4px 0; color: #221F1F; border-bottom-right-radius: 5px; }
    .sideMenuItem {
        display: flex;
        width: 100%;
        height: 30px;
        padding: 2px 4%; /* Add padding around the text */    
        cursor: pointer; /* Change cursor to pointer when hovering */
        text-align: left; /* Align text to the left */ 
        transition: background-color 0.3s ease; /* Smooth transition for background color */
    }
    
    /* Hover effect for sideMenuItem */
    .sideMenuItem:hover {
        background-color: #f1f1f1; /* Change background color on hover */
        color: #29B3CB; /* Change text color on hover */
        font-weight: bold; /* Make text bold on hover */
    }
}