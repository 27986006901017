    #conMainConWrap { gap:10px 2%; width: 100%; height: auto; display: grid; grid-template:repeat(10, 250px)/repeat(2, 45%);}
    /* Style for .conMainConElem container */
    .conMainConElem {
        background-color: #fff; /* Background color for the container */
        display: flex; /* Use flexbox layout */
        width: 100%; /* Set the width to 100% */
        flex-direction: column; /* Arrange children in a column */
        height: 250px; /* Set the height of the container */
        transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions for background color and box-shadow */
        border-radius: 4px;
    }
/* Style for images within .conMainConElem */
.conMainConElem img {
    max-width: 100%; /* Images will not exceed the width of the container */
    height: 200px   ; /* Set the height of the images */
    object-fit: contain; /* Preserve aspect ratio and fit images within the container */
}
    #conMainWrapper {display: flex; flex-direction: column;}
    #mainConSlide {height: auto; width: 100%;}
    .nameHPcat{ width: 100%; height: 30px; padding: 4px 10%; text-align: center; line-height: 22px; color: #4E4C4C; font-size: large; overflow: hidden;}    
    .imageHPcat {width: 30%; height: 220px; text-align: center;}
    .subHPcats {width: 60%; margin-left: auto;padding: 2px 1%; }
    .belowHPcat{display: flex; padding: 0 1%;}
@media (max-width: 600px) {
    #conMainConWrap { justify-content:center; gap:10px 2%; width: 100%; height: auto; display: grid; grid-template:repeat(10, 250px)/repeat(1, 96%);}
    /* Style for .conMainConElem container */
    .conMainConElem {
        background-color: #fff; /* Background color for the container */
        display: flex; /* Use flexbox layout */
        width: 100%; /* Set the width to 100% */
        flex-direction: column; /* Arrange children in a column */
        height: 250px; /* Set the height of the container */
        transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions for background color and box-shadow */
        border-radius: 4px;
    }
    

    

/* Style for images within .conMainConElem */
.conMainConElem img {
    max-width: 100%; /* Images will not exceed the width of the container */
    height: 200px   ; /* Set the height of the images */
    object-fit: contain; /* Preserve aspect ratio and fit images within the container */
}


    #conMainWrapper {display: flex; flex-direction: column;}
    #mainConSlide {height: auto; width: 100%;}
    .nameHPcat{  width: 100%; height: 30px; padding: 4px 10%; text-align: center; line-height: 22px; overflow: hidden; color: #4E4C4C; font-size: large;}    
    .imageHPcat {width: 30%; height: 220px; text-align: center;}
    .belowHPcat{display: flex; padding: 0 1%;}
}