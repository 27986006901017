.orderSuccess {
    text-align: center;
    padding: 20px;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    max-width: 600px;
    margin: 50px auto;
  }
  
  .orderSuccess p {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .returnLink {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .returnLink:hover {
    background-color: #0056b3;
  }
  