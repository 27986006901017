
/* Style for the content head div */
#contentHeadDrDo {
    display: flex;
    background-color: #ffffff; /* Light background color */
    padding: 10px 0; /* Padding around the content */    
}
/* Style for each menu item */
.menuItem {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-right: 2%;       
}
.subMenuItem {

    color: rgb(80, 76, 76);
    padding: 3px 2%; /* Padding for each item */    
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s; /* Smooth background color transition */
    text-align: left;
    height: 24px;
    line-height: 18px;
}
.subMenuItemHead {
    color: rgb(80, 76, 76);
    padding: 3px 2%; /* Padding for each item */    
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s; /* Smooth background color transition */
    text-align: center;
    height: 30px;
    line-height: 24px;
}
/* Hover effect for each menu item */
.subMenuItem:hover, .subMenuItemHead:hover {
    background-color: #e9ecef; /* Light grey background on hover */
}