#forHeader {width: 100%; min-width: 1600px; height: 70px; background-color: aquamarine; z-index: 1; display: flex; position: fixed;
    background-color: #221F1F;
    color: #ffffff;    
    text-align: center;
    display: flex;    
    align-items: center; /* Center items vertically */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for depth */}
.headerDivs {width: 20%; height: 70px;  display: flex; justify-content: center; align-items: center; }


#cabinetField {margin-left: auto; width: 40%; }
.divscabinetField {
    color: white;
    padding: 10px;
    width: 20%; /* Add padding to the inner divs */
    margin: 0 10px; /* Add margin between the inner divs */
    border-radius: 5px; /* Add rounded corners to the divs */
    cursor: pointer; /* Change cursor to pointer to indicate interactivity */
    transition: background-color 0.3s, color 0.3s; /* Add transition for smooth color change */
}

/* Add hover effect to the inner divs */
.divscabinetField:hover {
    background-color: #f0f0f0; /* Change background color on hover */
    color: #007bff; /* Change text color on hover */
}
#Pip {display: flex; width: 20%; text-align: center; justify-content: center; align-items: center;}
img {max-width: 100%; height: 70px; }
#forButCateg {
    display: flex;
    justify-content: center;
    background-color: #4E4C4C;
    width: 60%;    
    color: white; /* White text color */
    padding: 10px 20px; /* Padding for spacing */
    margin: 10px; /* Margin for spacing */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s ease; /* Transition effect */
}
.headIcons {margin-left: auto; }
#jiop {font-size: 1.35em; font-weight: 100;}
/* Hover state */
#forButCateg:hover {
    background-color: #7A7979; /* Darker green on hover */
}
/* Search input styles */
input[type="text"] {
    width: 100%; /* Set the width */
    padding: 8px; /* Add padding inside the input */
    border: 1px solid #ccc; /* Light border color */
    border-radius: 4px; /* Rounded corners */
    font-size: 14px; /* Font size */
    color: #333; /* Text color */
    background-color: #fff; /* Background color */
    outline: none; /* Remove default outline */
}
.forDisable {display: flex;}
#quantBucket {background-color: yellow; color: #333; border-radius: 50%; padding: 2px 0.5%; position: absolute; top: 10px;right: 1%;}
/* Small devices (phones) */
@media (max-width: 600px) {
    #jiop {font-size: 1.35em; font-weight: 100;}
    #quantBucket {background-color: yellow; color: #333; border-radius: 50%; padding: 2px 1%; position: absolute; top: 10px;right: 5%;}
    #kolad{width: 50%;}
    #forHeader {width: 100%; min-width: 300px; max-width: 560px; height: 70px;  display: flex; position: fixed; 
        background-color: #221F1F;
        color: #ffffff;    
        text-align: center;
        display: flex;    
        align-items: center; /* Center items vertically */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for depth */}
    .headerDivs {width: 15%; height: 70px;  display: flex; justify-content: center; align-items: center;}
    .forDisable {display: none;}
    #cabinetField {margin-left: auto; width: 20%; }
    .divscabinetField {
        padding: 10px 0;
        width: 20%; /* Add padding to the inner divs */
        margin: 0 10px; /* Add margin between the inner divs */
        border-radius: 5px; /* Add rounded corners to the divs */
        cursor: pointer; /* Change cursor to pointer to indicate interactivity */
        transition: background-color 0.3s, color 0.3s; /* Add transition for smooth color change */
    }
    
    /* Add hover effect to the inner divs */
    .divscabinetField:hover {
        background-color: #f0f0f0; /* Change background color on hover */
        color: #007bff; /* Change text color on hover */
        
    }
    #Pip {display: flex;}
    img {max-width: 100%; height: 70px; }
    #forButCateg {
        display: flex;
        justify-content: center;
        background-color: #4E4C4C;
        width: 60%;    
        color: white; /* White text color */
        padding: 10px 20px; /* Padding for spacing */
        margin: 10px; /* Margin for spacing */
        border: none; /* No border */
        border-radius: 5px; /* Rounded corners */
        font-size: 16px; /* Font size */
        font-weight: bold; /* Bold text */
        cursor: pointer; /* Pointer cursor */
        transition: background-color 0.3s ease; /* Transition effect */
    }
    .headIcons {margin-left: 0; }
    
    /* Hover state */
    #forButCateg:hover {
        background-color: #7A7979; /* Darker green on hover */
    }
    /* Search input styles */
    input[type="text"] {
        width: 100%; /* Set the width */
        padding: 8px; /* Add padding inside the input */
        border: 1px solid #ccc; /* Light border color */
        border-radius: 4px; /* Rounded corners */
        font-size: 14px; /* Font size */
        color: #333; /* Text color */
        background-color: #fff; /* Background color */
        outline: none; /* Remove default outline */
    }
    /* Small devices (phones) */
}