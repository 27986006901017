@media (min-width:601px) {
    #wrapMainCon3{ padding: 20px 10%; display: flex;}
#checkoutDetails {width: 45%; min-height: 80vh;  margin-right: 2%; padding: 5px 2%; display: flex; flex-direction: column; border: 1px solid #E9E9E9;}
#MainHName {margin-bottom: 20px;}
#orderList {width: 100%; min-height:30vh; display: flex; flex-direction: column; padding: 10px 3%; }
.productItemCO {display: flex; width: 100%; height: 100px; border: 1px solid #E9E9E9; margin-bottom: 5px; padding: 10px 2%; border-radius: 10px;}
.imageCO {width: 20%;  display: flex; justify-content: center;}
.imageCO > img {height: 77px;}
.nameCO {display: flex; width: 60%; }
.quantCO {display: flex; width: 10%; }
.priceCO {display: flex; width: 10%; }
#contactData, #forNotes, #delivery {display: flex; flex-direction: column; width: 100%;  min-height: 200px; padding: 10px 3%; border: 1px solid #E9E9E9; border-radius: 10px; margin-bottom: 10px;}
#contactsMName {margin-bottom: 20px;}
#deliveryAndReceiver {display: flex; flex-direction: column; width: 100%;  min-height: 200px; padding: 10px 3%; border: 1px solid #E9E9E9; border-radius: 10px;}

#checkoutApply {width: 50%; min-height: 300px; padding: 5px 2%;  display: flex; flex-direction: column; border: 1px solid #E9E9E9;}
#applySum {font-size: 1.5em; width: 100%; padding: 20px 2%; border-bottom: 1px solid #E9E9E9; margin-bottom: 10px;}
#buttonApply {text-decoration: none; background-color: #00BC52; color:#fff; border: none; width: 100%; height: 60px; padding: 5px 5%; 
    border-radius: 5px; font-size: 1.2em; cursor: pointer;}
/* Maincontent3.module.css */

#ErrorM {
    position: absolute;
    top: 50vh; /* Adjust based on where you want the error message to appear */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.8); /* Semi-transparent red background */
    color: white;
    padding: 20px 5%;
    border-radius: 5px;
    z-index: 1000; /* Ensure it appears above other elements */
    font-size: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


    .formGroupgroup {
        margin-bottom: 20px;
    }
    
    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
    
    
    textarea[type="text"],
    select {
        width: 100%;
        padding: 10px;
        min-height: 300px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
        transition: border-color 0.3s ease;
    }
    
    textarea[type="text"]:focus,
    select:focus {
        border-color: #007bff;
    }
    #NewPost, #selfServe { width: 100%; margin-bottom: 20px; height: 250px; display: flex; flex-direction: column; }
    #postChoices {display: flex; width: 100%; flex-direction: column; }
    #postChoices > div {display: flex; margin-right: 2%; }
    #postCityList {background-color: rgba(198, 202, 200, 0.8); position: fixed; width: 100%; right: 0; height: 100vh; overflow-y: scroll; top:70px; z-index: 1; }
    #postCityListIt {padding: 0 0.5%; border-radius: 5px;  background-color: #e3e7ec; width: 15%; min-height: 100vh; margin-left: auto;}
    .TopElement {cursor: pointer; font-size: 1.5em;}     
    .chooseDeliveryOptionActive {background-color: #007bff; margin-right: 5%; width: 30%; cursor: pointer; border-radius: 4px; text-align: center;}
    .PostLogo { margin-bottom: 20px; display: flex; align-items: center;}
    .PostLogo > img {height: 30px; border-radius: 50%; margin-right: 2%;}
    #contForPostChoices { width: 100%; height: 25px; display: flex; align-items: center; margin-bottom: 25px; }
    .chooseDeliveryOption { background-color: #c8d7e7; margin-right: 5%; width: 30%; cursor: pointer; border-radius: 4px; text-align: center;  }
    .itemForCityandDepart {background-color: #fff; margin-bottom: 5px; cursor: pointer; margin-top: 5px; border-radius: 2px;}
}

@media (max-width:600px) {
    /* Maincontent3.module.css */

#ErrorM {
    position: absolute;
    top: 20px; /* Adjust based on where you want the error message to appear */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.8); /* Semi-transparent red background */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000; /* Ensure it appears above other elements */
    font-size: 14px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

    #wrapMainCon3{ padding: 5px 1%; display: flex; flex-direction: column;}
#checkoutDetails {width: 100%; min-height: 80vh;  margin-right: 0; padding: 0 0; display: flex; flex-direction: column;}
#MainHName {margin-bottom: 20px;}
#orderList {width: 100%; min-height:30vh; display: flex; flex-direction: column; padding: 0; }
.productItemCO {display: flex; width: 100%; height: 100px; border-bottom: 1px solid #ccc; margin-bottom: 5px; padding: 0; }
.imageCO {width: 20%;  display: flex; justify-content: center;}
.imageCO > img {height: 77px;}
.nameCO {display: flex; width: 50%; }
.quantCO {display: flex; width: 10%; }
.priceCO {display: flex; width: 10%; }
#contactData, #forNotes, #delivery {display: flex; flex-direction: column; width: 100%;  min-height: 200px; padding: 10px 3%; border: 1px solid #E9E9E9; border-radius: 10px; margin-bottom: 10px;}
#contactsMName {margin-bottom: 20px;}
#deliveryAndReceiver {display: flex; flex-direction: column; width: 100%;  min-height: 200px; padding: 10px 3%;  border-radius: 10px;}

#checkoutApply {width: 100%; min-height: 300px; padding: 0;  display: flex; flex-direction: column; }
#applySum {font-size: 1.5em; width: 100%; padding: 0; border-bottom: 1px solid #E9E9E9; margin-bottom: 10px;}
#buttonApply {text-decoration: none; background-color: #00BC52; color:#fff; border: none; width: 100%; height: 60px; padding:0; 
    border-radius: 5px; font-size: 1.2em; cursor: pointer;}


    .formGroupgroup {
        margin-bottom: 20px;
    }
    
    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
    
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    select {
        width: 100%;
        padding: 0;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
        transition: border-color 0.3s ease;
    }
    
    input[type="text"]:focus,
    input[type="email"]:focus,
    input[type="tel"]:focus,
    input[type="number"]:focus,
    select:focus {
        border-color: #007bff;
    }
    
}