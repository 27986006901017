@media (min-width:601px) {
    #mainContainer {width: 100%; min-height: 90vh;  display: flex; padding: 20px 0.5%;}
#subConMenu {width: 20%; min-height: 300px; max-height: auto; display: flex; flex-direction: column;  padding: 10px 0.5%; 
border-right: 1px solid #DDDEE4; margin: 10px 2%;}
#subConContent {width: 80%; height: auto; display: flex; flex-direction: column;  }
.sideMenuItemSubCon, .subSubMenu > p, .MyPs {
    color: inherit;
    display: flex;
    width: 100%;
    height: 30px;
    padding: 2px 4%; /* Add padding around the text */    
    cursor: pointer; /* Change cursor to pointer when hovering */
    text-align: left; /* Align text to the left */ 
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    font-size:medium;
}
.subSubMenu {
    color: inherit;
    margin-left: auto;    
    display: flex;  
    min-height: 40px;
    flex-direction: column;
    width: 90%;
    padding: 2px 2%;
    border-left: 1px solid #cac5c5;
}
.subMenuItems {margin-left: 3%;}
/* Hover effect for sideMenuItem */
.sideMenuItemSubCon:hover, .subSubMenu > p:hover {
    background-color: #f1f1f1; /* Change background color on hover */
    color: #29B3CB; /* Change text color on hover */
    font-weight: bold; /* Make text bold on hover */
}
#subConContent {    
    border-radius: 5px;    
    background-color: #f9f9f9;
}
#productInfo {
    padding: 20px 2%;
    width: 100%;
    display: flex;
    flex-direction: column;    
    margin-bottom: 20px;    
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}
#prImage {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 40%;
    height: 410px;
}
#prImage img {   
    padding: 10px 0;    
    max-width: 50%; /* Make the image fill the entire container */
    min-height: 280px; /* Maintain aspect ratio */
    border-radius: 5px;
}
#purchaseSection {
    border-top: 1px solid #ddd;
    border-bottom:  1px solid #ddd;
    height: 70px;
    top: 10px;
    display: flex;
    width: 100%;
    padding: 10px 15%;
    justify-content: space-between;
    align-items: center;
}
#prPrice span {
    color: #F84147; /* Red color for price */
    font-weight: 500;
    font-size:30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
#buyButton {
    padding: 15px 4%;
    background-color: #28a745; /* Green background */
    color: #fff;
    width: 40%;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size:20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

#buyButton:hover {
    background-color: #218838;
}
#firstRow {display: flex; width: 100%; border-top:  1px solid #ddd;}
/* Product details container */
#prDescript {
    color: #514c4c;
    width: 60%;
    padding: 10px 2%;   
    border-radius: 5px;    
}
#prDescript > div {
    margin-bottom: 8px; /* Add spacing between text elements */
    display: flex;
    padding: 5px 0;
}
#prName {
    display: flex;
    align-items: center;
    color: #514c4c;
}
.leftDescript { width: 30%; font-weight: bold;}
#prMater {border-bottom: 1px solid #DDDEE4;}
#prDecr {
    font-size: 0.9rem;
    color: #514c4c;
    line-height: 1.5;
}
.isonWare {width: 20%; margin-left: 5%; color: #666666; background-color: #fff; border: 1px solid #4ae96f; 
    height: 30px; text-align: center; line-height: 30px; border-radius: 4px;}


#LoadDiv {background-color: #218838; width: 100%; height: 100vh;}

.clDelivery {color: #514c4c;}
#prDelivery {display: flex; flex-direction: column;}
#hiddenImageCont {justify-content: center; align-items: center; display: none; position: absolute; background-color: rgba(26, 20, 20, 0.2); 
    width: 100%; min-height: 100vh; top:0;left:0;}

#secondRow {margin-top: 20px; border-top: 1px solid #DDDEE4; padding: 10px 2%;}
#nothiddenImageCont{justify-content: center; align-items: center; display: flex; position: absolute; background-color: rgba(49, 35, 35, 0.786); 
    width: 100%; min-height: 100vh; top:0;left:0;}
}
#closeImage{position: absolute; top:80px; right: 5%; font-size: 2.5em; color: #fff; cursor: pointer;}
#closeImage:hover {font-size: 3em;}
#ImageForHidden{display: flex; height: 500px; background-color: #fff; border-radius: 6px;}
#forBrandImage {height: 50px;}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    position: absolute;
    top:42vh;
    left:48%;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
@media (max-width: 600px) {
    #closeImage{position: absolute; top:80px; right: 5%; font-size: 2.5em; color: #9f9595; cursor: pointer;}
    #closeImage:hover {font-size: 3em;}
    #ImageForHidden{display: flex; height: 500px; background-color: #fff; border-radius: 6px;}
    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 60px;
        position: absolute;
        top:42vh;
        left:48%;
        height: 60px;
        animation: spin 2s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    #nothiddenImageCont{justify-content: center; align-items: center; display: flex; position: absolute; background-color: rgba(49, 35, 35, 0.786); 
        width: 100%; min-height: 100vh; top:0;left:0;}
    #hiddenImageCont {justify-content: center; align-items: center; display: none; position: absolute; background-color: rgb(153, 86, 86, 0.2); width: 100%; 
        min-height: 100vh;left:0;}
#hiddenImageCont > img {display: flex; height: 200px; background-color: #4ae96f; }
    #mainContainer {width: 100%; min-height: 90vh;  display: flex; flex-direction: column; padding: 20px 0.5%;}
#subConMenu {width: 20%; min-height: 300px; max-height: auto; display: none; flex-direction: column;  padding: 10px 0.5%; 
border-right: 1px solid #DDDEE4; margin: 10px 2%;}
#subConContent {width: 80%; height: auto; display: flex; flex-direction: column;  }
.sideMenuItemSubCon, .subSubMenu > p, .MyPs {
    color: inherit;
    display: flex;
    width: 100%;
    height: 30px;
    padding: 2px 4%; /* Add padding around the text */    
    cursor: pointer; /* Change cursor to pointer when hovering */
    text-align: left; /* Align text to the left */ 
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    font-size:medium;
}
.subSubMenu {
    color: inherit;
    margin-left: auto;    
    display: flex;  
    min-height: 40px;
    flex-direction: column;
    width: 90%;
    padding: 2px 2%;
    border-left: 1px solid #cac5c5;
}
.subMenuItems {margin-left: 3%;}
/* Hover effect for sideMenuItem */
.sideMenuItemSubCon:hover, .subSubMenu > p:hover {
    background-color: #f1f1f1; /* Change background color on hover */
    color: #29B3CB; /* Change text color on hover */
    font-weight: bold; /* Make text bold on hover */
}

    /* Container styles */
    #subConContent {
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 20px;
    }

/* Container styles */
#subConContent {
    width: 100%;
    padding: 20px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin:0;    
    background-color: #f9f9f9;
}

/* Product info container */
#productInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;    
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

#prImage {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 410px;
}
#prImage img {   
    padding: 10px 0;    
    max-width: 50%; /* Make the image fill the entire container */
    height: 300px; /* Maintain aspect ratio */
    border-radius: 5px;
}
#firstRow {display: flex; flex-direction: column; width: 100%;}
/* Product details container */
#prDescript {
    width: 100%;
    padding: 10px 0;
    background-color: #f0f8ff; /* Light aqua background */
    border-radius: 5px;

}

#prDescript > div {
    margin-bottom: 8px; /* Add spacing between text elements */
    display: flex;
    padding: 5px 0;
}

#prName {
    color: #221F1F;
}


.leftDescript { width: 30%; font-weight: bold;}
#prMater {border-bottom: 1px solid #DDDEE4;}
#prDecr {
    font-size: 0.9rem;
    color: #333;
    line-height: 1.5;
}

#purchaseSection {
    border-top: 1px solid #ddd;
    border-bottom:  1px solid #ddd;
    height: 70px;
    top: 10px;
    display: flex;
    width: 100%;
    padding: 10px 15%;
    justify-content: space-between;
    align-items: center;
}

#prPrice span {
    color: #F84147; /* Red color for price */
    font-weight: 500;
    font-size:30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* Buy button */
#buyButton {
    padding: 15px 4%;
    background-color: #28a745; /* Green background */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size:20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

#buyButton:hover {
    background-color: #218838;
}
.clDelivery {color: #666666;}
#prDelivery {display: flex; flex-direction: column;}
#secondRow {margin-top: 20px; border-top: 1px solid #DDDEE4; padding: 10px 2%;}
}